<template>
    <div class="orders">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h4 class="box-title">Top cursos </h4>
              <p v-if="!settings_obj.ver_boleta">No hay datos para mostra aún.</p>
              <p>Cursos con mejor nota</p>
              <canvas id="singelBarChart"></canvas>
            </div>
          </div> <!-- /.card -->
        </div>  <!-- /.col-lg-8 -->
        </div>
    </div>
</template>
<script>
// @ is an alias to /src
import 'chartist/dist/chartist.css';
import Chart from 'chart.js';

/* eslint-disable */
export default {
  name: 'Top',
  created(){
    this.list();
  },
  data() {
    return {
      list_data: [],
      url: 'top_course/',
      label_course: [],
      values: [],
      sending: false,
    };
  },
  methods: {
    list() {
      if(!this.settings_obj.ver_boleta) return false
      const payload = {};
      const self = this;
      this.sending = true
      this.sendRequest(payload).then((data) => {
        self.list_data = data.data;            
        self.barChart(self.list_data)
      }).catch((error) => {
        console.log(error);
        self.$swal.fire('Error!', 'No fue posible autenticar.', 'error');
      });
      return null;
    },
    async sendRequest(payload) {
      const result = await this.$http.get(this.url, payload);
      return result;
    },
    async to_course_array(data) {
      this.label_course = Array.from(data, (x) => x.course_name);
      return [];
    },
    async to_data_array(data) {
      this.values = Array.from(data, (x) => x.note);
      return [];
    },
    async barChart(data) {
      const ctx = document.getElementById('singelBarChart');
      if (!ctx) return;
      await this.to_data_array(data)
      await this.to_course_array(data)
      ctx.height = 150;
      // eslint-disable-next-line no-new
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.label_course,
          datasets: [
            {
              label: 'Cursos',
              data: this.values,
              borderColor: 'rgba(0, 194, 146, 0.9)',
              borderWidth: '0',
              backgroundColor: 'rgba(0, 194, 146, 0.5)',
            },
          ],
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
              },
            }],
          },
        },
      });
      this.sending = false
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    }
  },
  computed: {
    settings_obj() {
      return this.$store.state.settings_obj;
    },
  },
};

</script>
<style lang="css" scoped>
    #weatherWidget .currentDesc {
        color: #ffffff!important;
    }
    .traffic-chart {
        min-height: 335px;
    }
    #flotPie1  {
        height: 150px;
    }
    #flotPie1 td {
        padding:3px;
    }
    #flotPie1 table {
        top: 20px!important;
        right: -10px!important;
    }
    .chart-container {
        display: table;
        min-width: 270px ;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    #flotLine5  {
          height: 105px;
    }

    #flotBarChart {
        height: 150px;
    }
    #cellPaiChart{
        height: 160px;
    }
    .on-over {
      cursor: pointer;
    }
</style>
